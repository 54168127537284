@charset 'UTF-8';

// transition
// タイミングを統一したい場合に使用
$transition: .2s ease-out;



// keyframs

// display: none; からのフェードイン
//
@keyframes show {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes transY {
  0% {
      margin-top: -16px;
  }
  100% {
      margin-top: 0;
  }
}

@keyframes sliderTrans {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center 0;
  }
}

@keyframes menuVisible {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes markerBorder {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
