@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes sliderTrans {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center 0;
  }
}

@keyframes menuVisible {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes markerBorder {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.swiper-wrapper {
  height: 120vw;
}

@media screen and (min-width: 61.25em) {
  .swiper-wrapper {
    height: 100%;
  }
}

.swiper-slide.sp-only {
  display: block;
}

@media screen and (min-width: 61.25em) {
  .swiper-slide.sp-only {
    display: none;
  }
}

.swiper-slide.pc-only {
  display: none;
}

@media screen and (min-width: 61.25em) {
  .swiper-slide.pc-only {
    display: block;
  }
}

.swiper-wrapper-sm {
  display: block;
}

@media screen and (min-width: 61.25em) {
  .swiper-wrapper-sm {
    display: none;
  }
}

.swiper-wrapper-pc {
  display: none;
}

@media screen and (min-width: 61.25em) {
  .swiper-wrapper-pc {
    display: block;
    height: 100%;
  }
}

.top-slider-hero {
  position: relative;
  height: auto;
}

@media screen and (min-width: 61.25em) {
  .top-slider-hero {
    height: 90vh;
  }
}

.top-slider-images {
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 61.25em) {
  .top-slider-images {
    background-size: cover;
  }
}
