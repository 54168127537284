@charset 'UTF-8';

// 関数
@import 'lib/functions';

// 変数
@import 'variables/variables';

// Mixin
@import 'lib/mixin';



// 上書き
.swiper-wrapper {
  height: 120vw;
  @include media(lg){
    height: 100%;
  }
}

.swiper-slide {
  &.sp-only {
    display: block;
    @include media(lg){
      display: none;
    }
  }
  &.pc-only {
    display: none;
    @include media(lg){
      display: block;
    }
  }
}

.swiper-wrapper-sm {
  display: block;
  @include media(lg){
    display: none;
  }
}

.swiper-wrapper-pc {
  display: none;
  @include media(lg){
    display: block;
    height: 100%;
  }
}



// Slider
.top-slider-hero {
  position: relative;
  height: auto;
  @include media(lg){
    height: 90vh;
  }
}

.top-slider-images {
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include media(lg){
    background-size: cover;
  }
}
